import { configureStore } from "@reduxjs/toolkit";
import resourcePlanSlice from "@src/services/resourcePlanSlice";
import { managersSlice } from "@src/services/slices/managersSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import resourcePlanSliceV2 from "./services/resourcePlanSliceV2";
import requestOverviewSlice from "./services/requestOverviewSlice";
import { baseApiSlice } from "./services/slices/baseApiSlice";
import { departmentsSlice } from "./services/slices/departmentsSlice";
import { projectLeadsSlice } from "./services/slices/projectLeadsSlice";
import { regionsSlice } from "./services/slices/regionsSlice";
import { usersSlice } from "./services/slices/usersSlice";
import splitRoleSlice from "./services/splitRoleSlice";
import featureToggleSlice from "./services/featureToggleSlice";

const apis = [
  departmentsSlice,
  projectLeadsSlice,
  regionsSlice,
  usersSlice,
  managersSlice,
  baseApiSlice,
];

const slices = {
  requestOverviewSlice: requestOverviewSlice.reducer,
  resourcePlanSlice: resourcePlanSlice.reducer,
  resourcePlanSliceV2: resourcePlanSliceV2.reducer,
  splitRoleSlice: splitRoleSlice.reducer,
  featureToggleSlice: featureToggleSlice.reducer,
};

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: {
      ...slices,
      ...apis.reduce((reducers, slice) => {
        reducers[slice.reducerPath] = slice.reducer;
        return reducers;
      }, {}),
    },
    preloadedState,
    middleware: (getDefaultMiddleware: any) =>
      getDefaultMiddleware().concat(
        apis.map((api) => api.middleware).filter(Boolean)
      ),
  });
};

export type RootState = ReturnType<ReturnType<typeof setupStore>["getState"]>;
export type AppDispatch = ReturnType<typeof setupStore>["dispatch"];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
