import React, { useState } from "react";

import { TabItem } from "@src/types/antd_types";
import { Radio, RadioChangeEvent } from "antd";
import "./filter_tabs.less";

export interface FilterTabProps {
  defaultActiveConfig: TabItem;
  items: TabItem[];
  onChange: (key: string) => void;
  disabled?: boolean;
}

const FilterTabs: React.FC<FilterTabProps> = ({
  defaultActiveConfig,
  items,
  onChange,
  disabled = false,
}) => {
  const [activeOption, setActiveOption] = useState(
    items.find((item: TabItem) => item.key === defaultActiveConfig?.key)
  );

  function handleChange(e: RadioChangeEvent) {
    setActiveOption(items.find((item: TabItem) => item.key === e.target.value));
    onChange(e.target.value);
  }

  return (
    <Radio.Group
      disabled={disabled}
      size={"large"}
      defaultValue={activeOption?.key}
      onChange={handleChange}
      data-testid="tabs-group"
      className="tabs"
    >
      {items.map((item: TabItem) => (
        <Radio.Button
          key={item.key}
          value={item.key}
          data-testid={`tab-${item.key}`}
        >
          <div className="tab-label">
            {item?.icon}
            {item.label}
          </div>
        </Radio.Button>
      ))}
    </Radio.Group>
  );
};

export default FilterTabs;
