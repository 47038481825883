import { EndpointBuilder } from "@reduxjs/toolkit/dist/query/endpointDefinitions";
import { RoleRequest } from "@src/types/role_request_types";
import { message } from "antd";

import { baseApiSlice } from "./baseApiSlice";

export const projectsApi = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get role requests by project id
     */
    getRoleRequestsByProjectId: builder.query<RoleRequest[], string>({
      query: (projectId) => `/v2/projects/${projectId}/role-requests`,
      transformErrorResponse: async () => {
        message.error("Failed to get role requests by project id", 5);
      },
    }),
  };
}

export const {
  /* GET endpoints */
  useGetRoleRequestsByProjectIdQuery,
} = projectsApi;
