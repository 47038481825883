import React from "react";

import {
  CloseOutlined,
  MinusCircleOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { REQUEST_STATUS } from "@src/constants";
import { setDetailsModalVisible } from "@src/services/requestOverviewSlice";
import { useAppDispatch } from "@src/setupStore";
import { RequestOverviewDetails } from "@src/types";
import { Button } from "antd";
import "./staffing_request_modal_action_handlers.less";

interface StaffingRequestModalActionHandlersProps {
  request: RequestOverviewDetails;
}

const statusMap: { [key: string]: string } = {
  Requested: REQUEST_STATUS.REQUESTED,
  "In recruitment": REQUEST_STATUS.IN_RECRUITMENT,
  Confirmed: REQUEST_STATUS.CONFIRMED,
  Proposed: REQUEST_STATUS.PROPOSED,
};

const StaffingRequestModalActionHandlers: React.FC<
  StaffingRequestModalActionHandlersProps
> = ({ request }) => {
  const dispatch = useAppDispatch();

  function getActionsForRequest() {
    if (!request) return [];
    const commonActions: React.ReactNode[] = [
      <Button
        key="back"
        size={"large"}
        icon={<CloseOutlined />}
        onClick={() => dispatch(setDetailsModalVisible(false))}
      >
        Cancel
      </Button>,
    ];

    const actions: React.ReactNode[] = [...commonActions];
    switch (statusMap[request.status]) {
      case REQUEST_STATUS.REQUESTED:
        actions.push(
          <Button
            key="save"
            type="primary"
            size={"large"}
            icon={<SendOutlined />}
          >
            Send
          </Button>
        );
        break;
      case REQUEST_STATUS.IN_RECRUITMENT:
        actions.push(
          <Button
            key="removeFromRecruitment"
            type="primary"
            size={"large"}
            className="warning-button"
          >
            Remove from Recruitment List
          </Button>
        );
        break;
      case REQUEST_STATUS.CONFIRMED:
        actions.push(
          <Button
            key="takeBack"
            type="primary"
            size={"large"}
            className="warning-button"
            icon={<MinusCircleOutlined />}
          >
            Take Back Proposal
          </Button>
        );
        break;
      case REQUEST_STATUS.PROPOSED:
        actions.push(
          <Button
            key="takeBack"
            type="primary"
            size={"large"}
            className="warning-button"
            icon={<MinusCircleOutlined />}
          >
            Take Back Proposal
          </Button>
        );
        break;
      default:
        return [];
    }

    return actions;
  }

  return <>{getActionsForRequest()}</>;
};

export default StaffingRequestModalActionHandlers;
