import "./draft_badge.less";
import { EditOutlined } from "@ant-design/icons";

const DraftBadge = () => {
  return (
    <div className="draft-badge">
      <EditOutlined />
      Draft
    </div>
  );
};

export default DraftBadge;
