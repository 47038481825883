import { Router } from "@reach/router";
import { ROUTES } from "@src/constants";
import useFeatureToggle from "@src/hooks/use_feature_toggle";
import { AdminProjectAssignmentsPage } from "@src/pages/admin/admin_project_assignments_page";
import { AdminRoleAssignmentPage } from "@src/pages/admin/admin_role_assignment_page";
import { EmployeeDataPage } from "@src/pages/admin/employee_data_page";
import { ImportResourcePlanPage } from "@src/pages/admin/import_resource_plan_page";
import { RegionAssignmentPage } from "@src/pages/admin/region_assignment_page";
import { RoleAssignmentPage } from "@src/pages/admin/role_assignment_page";
import { SapCsvUploadPage } from "@src/pages/admin/sap_csv_upload_page";
import { CreateProjectPage } from "@src/pages/create_project_page";
import { DashBoardPage } from "@src/pages/dashboard";
import { ProjectPage } from "@src/pages/project_page";
import { ProjectPageV2 } from "@src/pages/project_page_v2";
import { ProjectsOverviewPage } from "@src/pages/projects_overview_page";
import { StaffingRequestOverviewPage } from "@src/pages/staffing_requests_overview_page";
import { StartScreenPage } from "@src/pages/start_screen_page";
import { TeamLeadSuggestTeamMemberPage } from "@src/pages/team_lead_suggest_team_member_page";
import { TeamMemberOverviewPage } from "@src/pages/team_member_overview_page";

export const CustomRouter = () => {
  const isPLMergedTableEnabled = useFeatureToggle("plRedesign");

  return (
    <Router>
      <StartScreenPage path="/" />
      {isPLMergedTableEnabled ? (
        <ProjectPageV2 path={`${ROUTES.PROJECT_LEAD.PROJECTS}/:id`} />
      ) : (
        <ProjectPage path={`${ROUTES.PROJECT_LEAD.PROJECTS}/:id`} />
      )}
      <CreateProjectPage path={`${ROUTES.PROJECT_LEAD.PROJECTS}/create/:id`} />
      <ProjectsOverviewPage path={ROUTES.PROJECT_LEAD.PROJECTS} />
      <RegionAssignmentPage path={ROUTES.ADMIN.REGION_ASSIGNMENT} />
      <DashBoardPage path={ROUTES.MANAGER.DASHBOARD} />
      <RoleAssignmentPage path={ROUTES.ADMIN.ROLE_ASSIGNMENT_V2} />
      <AdminRoleAssignmentPage path={ROUTES.ADMIN.ROLE_ASSIGNMENT} />
      <AdminProjectAssignmentsPage path={ROUTES.ADMIN.PROJECT_ASSIGNMENTS} />
      <ImportResourcePlanPage path="/projectAssignments/:id/import" />
      <EmployeeDataPage path={ROUTES.ADMIN.EMPLOYEE_DATA} />
      <SapCsvUploadPage path={ROUTES.ADMIN.SAP} />
      <StaffingRequestOverviewPage path={ROUTES.TEAM_LEAD.STAFFING_REQUESTS} />
      <TeamLeadSuggestTeamMemberPage
        path={`${ROUTES.TEAM_LEAD.STAFFING_REQUESTS}/suggestTeamMember/:id`}
      />
      <TeamMemberOverviewPage path={ROUTES.TEAM_LEAD.TEAM_OVERVIEW} />
      <StartScreenPage path="*" />
    </Router>
  );
};
