// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-plan-table .ag-header {
  background-color: #ffffff !important;
}
.resource-plan-table .ag-header-cell-text {
  color: #3b3d42;
  font-size: 14px !important;
}
`, "",{"version":3,"sources":["webpack://./src/features/resource_plan_v2/components/resource_plan_table/resource_plan_table.less"],"names":[],"mappings":"AAEA;EAGI,oCAAA;AAHJ;AAKI;EACE,cAAA;EACA,0BAAA;AAHN","sourcesContent":["@import \"@src/stylings/variables\";\n\n.resource-plan-table {\n  // as soon as all tables have the same font color, we need to move this to the aggrid.less\n  .ag-header {\n    background-color: @white !important;\n\n    &-cell-text {\n      color: @font-00;\n      font-size: 14px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
