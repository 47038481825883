// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role-request-header {
  display: flex;
  gap: 5px;
}
.role-request-header-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
}
.role-request-header-title {
  font-size: 20px;
  text-transform: capitalize;
}
.role-request-header-notification {
  padding-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/role_request_modal_header/role_request_modal_header.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,QAAA;AACF;AAAE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AACE;EACE,eAAA;EACA,0BAAA;AACJ;AAEE;EACE,iBAAA;AAAJ","sourcesContent":[".role-request-header {\n  display: flex;\n  gap: 5px;\n  &-indicator {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &-title {\n    font-size: 20px;\n    text-transform: capitalize;\n  }\n\n  &-notification {\n    padding-top: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
