// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project_page_v2 .top-bar {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}
.project_page_v2 .top-bar h2,
.project_page_v2 .top-bar h5 {
  margin: 0;
}
.project_page_v2 .top-bar__button-title {
  display: flex;
}
.project_page_v2 .top-bar__button-title h2 {
  margin-left: 10px;
}
.project_page_v2 .top-bar__switch-details {
  display: flex;
}
`, "",{"version":3,"sources":["webpack://./src/pages/project_page_v2/project_page_v2.less"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,8BAAA;EACA,cAAA;AAAJ;AAJA;;EAOM,SAAA;AACN;AAEI;EACE,aAAA;AAAN;AADI;EAGI,iBAAA;AACR;AAGI;EACE,aAAA;AADN","sourcesContent":[".project_page_v2 {\n  .top-bar {\n    display: flex;\n    justify-content: space-between;\n    margin: 32px 0;\n    h2,\n    h5 {\n      margin: 0;\n    }\n\n    &__button-title {\n      display: flex;\n      h2 {\n        margin-left: 10px;\n      }\n    }\n\n    &__switch-details {\n      display: flex;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
