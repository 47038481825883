import React, { useEffect } from "react";

import {
  AimOutlined,
  CommentOutlined,
  InfoCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { RoleRequestBaseModal } from "@src/components/modals/role_request_modal";
import { REQUEST_STATUS } from "@src/constants";
import StaffingRequestCommentsTab from "@src/features/staffing_request_details/components/staffing_request_comments_tab/staffing_request_comments_tab";
import { StaffingRequestModalActionHandlers } from "@src/features/staffing_request_details/components/staffing_request_modal_action_handlers";
import { setDetailsModalVisible } from "@src/services/requestOverviewSlice";
import { useLazyGetNoteQuery } from "@src/services/slices/notesApiSlice";
import { useLazyGetTeamLeadRequestDetailsQuery } from "@src/services/slices/teamLeadsApi";
import { RootState, useAppSelector } from "@src/setupStore";
import { Note, RequestOverviewDetails } from "@src/types";
import { CustomTabItem } from "@src/types/antd_types";
import { useDispatch } from "react-redux";

const StaffingRequestDetails: React.FC = () => {
  const dispatch = useDispatch();

  const [request, setRequest] = React.useState<RequestOverviewDetails>();
  const [notes, setNotes] = React.useState<Note[]>([]);

  const { selectedRequestId, detailsModalVisible } = useAppSelector(
    (state: RootState) => state.requestOverviewSlice
  );

  // RTK Queries
  const [fetchTeamLeadRequestDetails] = useLazyGetTeamLeadRequestDetailsQuery();
  const [fetchNotes] = useLazyGetNoteQuery();

  useEffect(() => {
    if (selectedRequestId) {
      fetchRequestDetails(selectedRequestId);
    }
  }, [selectedRequestId]);

  async function fetchRequestDetails(requestId: number) {
    try {
      const requestDetails: RequestOverviewDetails =
        await fetchTeamLeadRequestDetails(requestId).unwrap();
      setRequest(requestDetails);

      // fetch notes
      const notes = await fetchNotes(requestId).unwrap();
      setNotes(notes);
    } catch (error) {
      console.log(error);
    }
  }

  function areProposedTabsHidden(status: string): boolean {
    return [
      REQUEST_STATUS.PROPOSED,
      REQUEST_STATUS.CONFIRMED,
      REQUEST_STATUS.IN_RECRUITMENT,
      REQUEST_STATUS.DECLINED,
    ].includes(status);
  }

  const tabs: CustomTabItem[] = [
    {
      key: "0",
      icon: <InfoCircleOutlined />,
      label: `Overview`,
      children: `Nothing to show here`,
    },
    {
      key: "1",
      icon: <UserOutlined />,
      label: `Propose Team Member`,
      children: `Nothing to show here`,
      isHidden: areProposedTabsHidden,
    },
    {
      key: "2",
      icon: <AimOutlined />,
      label: `Propose Other Solution`,
      children: `Nothing to show here`,
      isHidden: areProposedTabsHidden,
    },
    {
      key: "3",
      icon: <CommentOutlined />,
      label: `Comments (${notes?.length})`,
      children: <StaffingRequestCommentsTab notes={notes} />,
    },
  ];

  return (
    <RoleRequestBaseModal
      setModalVisible={(visible: boolean) =>
        dispatch(setDetailsModalVisible(visible))
      }
      modalVisible={detailsModalVisible}
      actions={<StaffingRequestModalActionHandlers request={request} />}
      tabs={tabs}
      requestedRoleId={request?.projectRoleRequestId}
      requestedRoleStatus={request?.status}
      requestedRoleName={request?.genericRole}
    />
  );
};

export default StaffingRequestDetails;
