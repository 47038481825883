import { EndpointBuilder } from "@reduxjs/toolkit/src/query/endpointDefinitions";
import { baseApiSlice } from "@src/services/slices/baseApiSlice";
import { Note, NoteHistory } from "@src/types";
import { message } from "antd";

export const notesApiSlice = baseApiSlice.injectEndpoints({
  endpoints: (builder: EndpointBuilder<any, any, any>) => ({
    /* GET endpoints */
    ...getEndpoints(builder),

    /* POST endpoints */
    ...postEndpoints(builder),
  }),
});

function getEndpoints(builder: EndpointBuilder<any, any, any>) {
  return {
    /**
     * Get a note
     */
    getNote: builder.query<Note[], number>({
      query: (requestId) => `notes/request/${requestId}`,
      transformErrorResponse: async () => {
        message.error("Failed to get note", 5);
      },
      providesTags: ["Notes"],
    }),
  };
}

function postEndpoints(builder: EndpointBuilder<any, any, any>) {
  const baseMethod = "POST";

  return {
    /**
     * Create a new note
     */
    addNote: builder.mutation<void, Note>({
      query: (note) => ({
        url: "notes",
        method: baseMethod,
        body: note,
      }),
      transformErrorResponse: async () => {
        message.error("Error creating new note", 5);
      },
      invalidatesTags: ["Notes"],
    }),

    /**
     * Save note read status
     */
    saveNoteStatus: builder.mutation<void, NoteHistory>({
      query: (note) => ({
        url: "notes/read-status",
        method: baseMethod,
        body: note,
      }),
      transformErrorResponse: async () => {
        message.error("Failed to save note read status", 5);
      },
      invalidatesTags: ["Notes"],
    }),
  };
}

export const {
  /* GET endpoints */
  useGetNoteQuery,
  useLazyGetNoteQuery,

  /* POST endpoints */
  useAddNoteMutation,
  useSaveNoteStatusMutation,
} = notesApiSlice;
