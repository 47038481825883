import { RootState, useAppSelector } from "@src/setupStore";

const useFeatureToggle = (featureToggleKey: string) => {
  const {
    featureToggleState: { toggledFeatures },
  } = useAppSelector((state: RootState) => state.featureToggleSlice);

  return toggledFeatures?.[featureToggleKey] ?? false;
};

export default useFeatureToggle;
