import profile from "@src/assets/icons/profile.svg";
import { Tooltip } from "antd";
import "./status.less";

interface StatusProps {
  status: string;
  assignedEmployeeFullName?: string;
}

const Status: React.FC<StatusProps> = ({
  status,
  assignedEmployeeFullName,
}) => {
  function formatStatusClassName(status: string) {
    return status?.toLowerCase()?.replace(" ", "-");
  }

  return (
    <div className="status">
      <div className="status-text">
        <div
          data-testid="status-icon"
          className={
            "status-icon " + `status-icon-${formatStatusClassName(status)}`
          }
        />
        <div>{status}</div>
      </div>
      {assignedEmployeeFullName && (
        <Tooltip placement="topLeft" title={assignedEmployeeFullName}>
          <div className="status-subtext" data-testid="tooltip">
            <img
              src={profile}
              alt="assigned-employee-profile-icon"
              className="status-profile-icon"
            />
            <span>{assignedEmployeeFullName}</span>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default Status;
