import React from "react";

import { Tooltip } from "antd";

import { months } from "../constants";
import { calculateTableHeader } from "./helper";

export const generateMonthColumns = (requestedData) => {
  const yearColumns = {};
  const yearlyAllocations = requestedData.yearlyAllocations;

  if (yearlyAllocations) {
    let roleAllocationDetailsLast;
    // when we have roleAllocationDetails, get the last object directly from it

    const roleAlloactionDetails =
      requestedData?.childRequest?.roleAllocationDetails?.length > 0
        ? requestedData.childRequest.roleAllocationDetails
        : requestedData.roleAllocationDetails;

    if (roleAlloactionDetails) {
      const lastYearIndex = roleAlloactionDetails.length;
      roleAllocationDetailsLast = roleAlloactionDetails[lastYearIndex - 1];
    } else {
      //if not, we need to get the information from yearlyAllocations
      const yearlyAllocationKeys = Object.keys(yearlyAllocations).sort(
        (a, b) => a - b
      );
      const lastYear = yearlyAllocationKeys[yearlyAllocationKeys.length - 1];
      const yearlyAllocationsLastYear = yearlyAllocations[lastYear];
      const lastYearMonthKeys = Object.keys(yearlyAllocationsLastYear).sort(
        (a, b) => a - b
      );
      const lastMonth = lastYearMonthKeys[lastYearMonthKeys.length - 1];
      roleAllocationDetailsLast = {
        year: lastYear,
        month: lastMonth,
      };
    }

    if (roleAllocationDetailsLast) {
      const dates = calculateTableHeader(null, roleAllocationDetailsLast);
      const totalMonths = dates.totalMonths;
      const noOfYears = dates.noOfYears;

      for (let y = dates.startYear; y <= dates.endYear; y++) {
        let currentStartMonth = 1;
        let currentEndMonth = 12;

        if (dates.startYear === dates.endYear) {
          currentStartMonth = dates.startMonth;
          currentEndMonth = 12;
        } else if (y === dates.startYear) {
          currentStartMonth = dates.startMonth;
        } else if (y === dates.endYear) {
          currentEndMonth = dates.endMonth;
          if (
            dates.startYear === dates.endYear - 1 &&
            totalMonths < 12 &&
            12 - dates.startMonth + 1 + dates.endMonth < 12
          ) {
            currentEndMonth +=
              12 - (12 - dates.startMonth + 1) - dates.endMonth;
          }
        }

        const noOfColumns =
          dates.noOfYears === 1
            ? 13 - currentStartMonth + currentEndMonth
            : 13 - currentStartMonth + currentEndMonth + (noOfYears - 1) * 12;

        for (let m = currentStartMonth; m <= currentEndMonth; m++) {
          if (!yearColumns[y]) {
            yearColumns[y] = { children: [] };
          }

          yearColumns[y].children.push({
            title: months[m - 1],
            dataIndex: `${y}_${months[m - 1]}`,
            key: `${y}_${months[m - 1]}`,
            width: 70,
            render: (currentRowState, record, index) => {
              let columnName = `${y}_${months[m - 1]}`;
              const isRowHeader =
                dates.startYear + "_" + months[dates.startMonth - 1] ===
                columnName;

              let isProposed = false;
              if (
                index === 3 &&
                yearlyAllocations?.[y] &&
                record[columnName] !== yearlyAllocations[y][m]
              ) {
                isProposed = true;
              }

              return requestedAllocation(
                currentRowState,
                index,
                isRowHeader,
                noOfColumns,
                y,
                m,
                requestedData,
                isProposed
              );
            },
          });
        }
      }
    }

    return Object.keys(yearColumns).map((year) => {
      return {
        title: year,
        children: yearColumns[year].children,
      };
    });
  }

  return [];
};

const requestedAllocation = (
  currentRowState,
  index,
  isRowHeader,
  noOfColumns,
  year,
  month,
  requestedData,
  isProposed
) => {
  let state = "NoChange";
  const matchAllocation =
    requestedData?.childRequest &&
    requestedData?.childRequest?.roleAllocationDetails.find((request) => {
      return request.year === year && request.month === month;
    });

  let percentageMatch;
  if (matchAllocation) {
    percentageMatch = matchAllocation.requiredPercentage + "%";

    if (percentageMatch !== currentRowState + "%") {
      if (matchAllocation.requiredPercentage > 0) {
        state = "modified";
      } else {
        state = "deleted";
      }
    }
  } else {
    state = "deleted";
  }

  const title =
    state === "modified"
      ? `Old=${
          currentRowState ? currentRowState : "[None] "
        } |  New=${percentageMatch}`
      : "";
  const percentage = currentRowState > 0 ? currentRowState + "%" : undefined;
  const proposedClass = isProposed ? "state-modified" : "";

  const obj = {
    children:
      index >= 2 ? (
        <span className={proposedClass}>{percentage}</span>
      ) : (
        <Tooltip title={title}>
          <span className={requestedData?.childRequest ? `state-${state}` : ""}>
            {state === "modified" ? percentageMatch : percentage}
          </span>
        </Tooltip>
      ),
    props: { colSpan: 0 },
  };
  if (index % 2 === 0) {
    if (isRowHeader) {
      obj.children = <span className="fixed-cell">{currentRowState}</span>;
      obj.props.colSpan = noOfColumns;
    }
  } else {
    obj.props.colSpan = 1;
  }
  return obj;
};
