// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.role_request_status_indicator {
  text-align: center;
}
.role_request_status_indicator-small {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.role_request_status_indicator-large {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.role_request_status_indicator-confirmed {
  background-color: #5ab88f;
}
.role_request_status_indicator-requested {
  background-color: #778fad;
}
.role_request_status_indicator-in-progress {
  background-color: #fff0b3;
}
.role_request_status_indicator-declined {
  background-color: #e7343f;
}
.role_request_status_indicator-in-recruitment {
  background-color: #fff0b3;
}
.role_request_status_indicator-proposed {
  background-color: #99e0f7;
}
`, "",{"version":3,"sources":["webpack://./src/components/role_request_status_indicator/role_request_status_indicator.less"],"names":[],"mappings":"AAEA;EACE,kBAAA;AADF;AAGE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AADJ;AAIE;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ;AAME;EACE,yBAAA;AAJJ;AAOE;EACE,yBAAA;AALJ;AAQE;EACE,yBAAA;AANJ;AASE;EACE,yBAAA;AAPJ;AAUE;EACE,yBAAA;AARJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.role_request_status_indicator {\n  text-align: center;\n\n  &-small {\n    width: 12px;\n    height: 12px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &-large {\n    width: 16px;\n    height: 16px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  &-confirmed {\n    background-color: @success;\n  }\n\n  &-requested {\n    background-color: @brand-60;\n  }\n\n  &-in-progress {\n    background-color: @yellow-30;\n  }\n\n  &-declined {\n    background-color: @alert;\n  }\n\n  &-in-recruitment {\n    background-color: @yellow-30;\n  }\n\n  &-proposed {\n    background-color: @energy-blue-40;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
