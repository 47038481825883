// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.status-renderer-text {
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 25px;
}
.status-renderer-subtext {
  display: flex;
  align-items: center;
  font-family: "RWESansWeb-Regular", serif;
  font-size: 12px !important;
  color: #3b3d42;
  line-height: 20px;
}
.status-renderer-subtext > span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 120px;
}
.status-renderer-profile-icon {
  height: 12px;
  width: 12px;
  margin: 0 !important;
}
`, "",{"version":3,"sources":["webpack://./src/custom_renderer/status_renderer/status_renderer.less"],"names":[],"mappings":"AAGE;EACE,aAAA;EACA,mBAAA;EACA,QAAA;EACA,iBAAA;AAFJ;AAKE;EACE,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,0BAAA;EACA,cAAA;EACA,iBAAA;AAHJ;AAHE;EAQI,uBAAA;EACA,gBAAA;EACA,YAAA;AAFN;AAME;EACE,YAAA;EACA,WAAA;EACA,oBAAA;AAJJ","sourcesContent":["@import \"@src/stylings/variables.less\";\n\n.status-renderer {\n  &-text {\n    display: flex;\n    align-items: center;\n    gap: 5px;\n    line-height: 25px;\n  }\n\n  &-subtext {\n    display: flex;\n    align-items: center;\n    font-family: \"RWESansWeb-Regular\", serif;\n    font-size: 12px !important;\n    color: @font-00;\n    line-height: 20px;\n    > span {\n      text-overflow: ellipsis;\n      overflow: hidden;\n      width: 120px;\n    }\n  }\n\n  &-profile-icon {\n    height: 12px;\n    width: 12px;\n    margin: 0 !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
