import { ROLES } from "@src/constants";
import RequestedRoleRenderer from "@src/custom_renderer/requested_role_renderer/requested_role_renderer";
import { StatusRenderer } from "@src/custom_renderer/status_renderer";
import { FrameworkComponents } from "@src/types/aggrid_types";
import { Allocation, RoleRequest } from "@src/types/role_request_types";
import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import {
  ColDef,
  GetQuickFilterTextParams,
  GridOptions,
  ValueGetterParams,
} from "ag-grid-community";
import { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";

import { getAllocationColumnDefs } from "./allocation_column_def_utils";

export function getGridOptions(): GridOptions {
  return {
    ...paginationGridOptions,
    ...preventPinnedColumnJumpingGridOption,
    rowHeight: 50,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    groupHeaderHeight: 26,
    headerHeight: 26,
    components: getComponents(),
    defaultColDef: {
      ...columnTypes.default,
      resizable: false,
    },
    context: {
      view: ROLES.PROJECT_LEAD,
    },
    rowSelection: {
      mode: "multiRow",
    },
    selectionColumnDef: {
      pinned: "left",
    },
  };
}

function getComponents(): FrameworkComponents {
  return {
    requestedRoleRenderer: (props: CustomCellRendererProps) => {
      return <RequestedRoleRenderer props={props} />;
    },
    statusRenderer: (props: CustomCellRendererProps) => {
      return <StatusRenderer props={props} />;
    },
  };
}

export function getColumnDefs(endDate: Date): ColDef[] {
  return [
    {
      field: "standardRoleName",
      headerName: "Requested Role",
      cellRenderer: "requestedRoleRenderer",
      pinned: "left",
      filter: "agSetColumnFilter",
      minWidth: 370,
      maxWidth: 400,
      getQuickFilterText: (params: GetQuickFilterTextParams<RoleRequest>) => {
        return `${params.data.roleRequestId} ${params.data.standardRoleName}`;
      },
    },
    {
      field: "status",
      headerName: "Status",
      cellRenderer: "statusRenderer",
      pinned: "left",
      filter: "agSetColumnFilter",
      minWidth: 128,
      maxWidth: 150,
    },
    {
      field: "projectIdentifier",
      headerName: "Project Identifier",
      pinned: "left",
      minWidth: 128,
      maxWidth: 150,
    },
    {
      field: "specificRole",
      headerName: "Specific Role",
      pinned: "left",
      minWidth: 128,
      maxWidth: 150,
    },
    {
      field: "scope",
      headerName: "Scope",
      pinned: "left",
      minWidth: 128,
      maxWidth: 150,
    },
    ...getAllocationColumnDefs(endDate, customValueGetter),
  ];
}

export function customValueGetter(
  params: ValueGetterParams,
  year: number,
  month: number
) {
  return (
    params.data.allocationDetails.find(
      (allocation: Allocation) =>
        dayjs(allocation.date).year() === year &&
        dayjs(allocation.date).month() === month
    )?.requiredPercentage || 0
  );
}
