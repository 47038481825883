import React from "react";

import "./requested_role_renderer.less";
import { navigate } from "@reach/router";
import comment from "@src/assets/icons/comment.svg";
import warning from "@src/assets/icons/warning.svg";
import { ROLES } from "@src/constants";
import useFeatureToggle from "@src/hooks/use_feature_toggle";
import {
  setDetailsModalVisible,
  setSelectedRequestId,
} from "@src/services/requestOverviewSlice";
import { useAppDispatch } from "@src/setupStore";
import { CustomCellRendererProps } from "ag-grid-react";
import { Button, Tooltip } from "antd";

interface RequestedRoleRendererParams {
  props: CustomCellRendererProps;
}

const RequestedRoleRenderer: React.FC<RequestedRoleRendererParams> = ({
  props,
}) => {
  const dispatch = useAppDispatch();
  const isTLModalDetailsEnabled = useFeatureToggle("tlWindowedRequestDetails");

  /* ToDo: when updating endpoints of Staffing Request, 
  the field "projectRoleRequestId" in the DTO should be renamed to
  "roleRequestId" */
  const roleRequestId =
    props.data.projectRoleRequestId || props.data.roleRequestId;

  const { basicRoleName, roleSpecificationName, actionRequired } = props.data;

  const commentAvailable =
    props.data.commentAvailable && !props.data.commentReadByEmployee;

  function onClick() {
    switch (props.context?.view) {
      case ROLES.TEAM_LEAD:
        handleTeamLeadViewNavigation();
        break;
      case ROLES.PROJECT_LEAD: // TODO when adding the popups
      default:
    }
  }

  function handleTeamLeadViewNavigation() {
    if (!isTLModalDetailsEnabled) {
      navigate(`staffingRequests/suggestTeamMember/${roleRequestId}`);
    } else {
      dispatch(setDetailsModalVisible(true));
      dispatch(setSelectedRequestId(roleRequestId));
    }
  }

  return (
    <div className="requested-role-renderer">
      <div className="requested-role-renderer-title-wrapper">
        <div className="requested-role-renderer-icon-wrapper">
          {actionRequired ? (
            <Tooltip
              mouseEnterDelay={0.5}
              placement="top"
              title="Action required"
            >
              <img
                src={warning}
                alt="warning"
                className="requested-role-renderer-icon"
              />
            </Tooltip>
          ) : (
            <div className="requested-role-renderer-icon empty" />
          )}
          <Button
            type="link"
            className="requested-role-renderer-button"
            onClick={onClick}
          >
            <span className="requested-role-renderer-button-text">
              {roleRequestId} {basicRoleName}
            </span>
          </Button>
        </div>
        <div className="requested-role-renderer-subtext">
          {roleSpecificationName}
        </div>
      </div>
      {commentAvailable && (
        <Tooltip
          mouseEnterDelay={0.5}
          placement="top"
          title="A comment is available"
        >
          <img
            src={comment}
            alt="comment"
            className="requested-role-renderer-icon"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default RequestedRoleRenderer;
