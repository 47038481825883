import React, { useState, useRef, useMemo, useEffect } from "react";

import { PlusOutlined } from "@ant-design/icons";
import ActionsRenderer from "@src/custom_renderer/actions_renderer/actions_renderer";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import {
  useCreateRegionMutation,
  useDeleteRegionMutation,
  useUpdateRegionMutation,
} from "@src/services/slices/regionsSlice";
import { Region } from "@src/types";
import {
  GridReadyEvent,
  GridSizeChangedEvent,
  ICellRendererParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import { App, Button } from "antd";
import _ from "lodash";

import { InputRenderer } from "../../custom_renderer/input_renderer";
import { onChange, isRegionValid } from "../../utils/region_assignment_utils";
import { getGridOptions } from "../../utils/region_settings_management_grid_options";
import { RegionAssignmentFilter } from "../region_assignment_filter";

import "./region_settings_management.less";

interface RegionSettingsManagementProps {
  regionsData: Region[];
  isLoading: boolean;
}

const RegionSettingsManagement: React.FC<RegionSettingsManagementProps> = ({
  regionsData,
  isLoading,
}) => {
  const gridApi = useRef(null);

  const { message } = App.useApp();

  const [disableAddNewRegionButton, setDisableAddNewRegionButton] =
    useState<boolean>(false);
  const [pinnedTopRowData, setPinnedTopRowData] = useState([]);
  const [regions, setRegions] = useState<Region[]>(regionsData);

  const [createRegion] = useCreateRegionMutation();
  const [updateRegion] = useUpdateRegionMutation();
  const [deleteRegion] = useDeleteRegionMutation();

  const context = useMemo(
    () => ({
      regions: regions,
    }),
    [regions]
  );

  useEffect(() => {
    setRegions(regionsData);
  }, [regionsData]);

  function onGridReady(params: GridReadyEvent): void {
    gridApi.current = params.api;
    gridApi.current.sizeColumnsToFit();
  }

  /**
   * onClick function of the edit button
   * @param {ICellRendererParams} props - ag-grid props
   */
  function onEdit(props: ICellRendererParams): void {
    props.data.isEditActive = true;
    gridApi.current.refreshCells({ rowNodes: [props.node], force: true });
  }

  /**
   * onClick function of the cancel button
   * @param {ICellRendererParams} props  - ag-grid props
   */
  function onCancel(props: ICellRendererParams): void {
    props.data.isEditActive = false;
    gridApi.current.redrawRows({ rowNodes: [props.node] });
  }

  /**
   * onClick function of the delete button
   * @param {ICellRendererParams} props - ag-grid props
   */
  function onDelete(props: ICellRendererParams): void {
    if (props.data.newRow) {
      setDisableAddNewRegionButton(false);
      setPinnedTopRowData([]);
    } else {
      deleteRegion(props.data.regionId)
        .unwrap()
        .then(() => {
          props.api.applyTransaction({ remove: [props.data] });
          message.success(`Successfully deleted region`);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});
    }
  }

  /**
   * onClick function of the save button
   * @param {ICellRendererParams} props - ag-grid props
   */
  async function handleSave(props: ICellRendererParams): Promise<void> {
    const isRegionSettingValid: boolean = isRegionValid(props, message);
    if (!isRegionSettingValid) return;

    if (props.data.newRow) {
      await addRegion(props);
    } else {
      await editRegion(props);
    }
  }

  /**
   * Adds a new region
   *
   * @param {ICellRendererParams} props - ag-grid props
   */
  async function addRegion(props: ICellRendererParams): Promise<void> {
    createRegion(props.data.updatedData)
      .unwrap()
      .then(() => {
        setDisableAddNewRegionButton(false);
        updateNodeData(props);
        props.api.setGridOption("pinnedTopRowData", []);
        message.success(`Successfully created new region`);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * Edits an existing region
   *
   * @param {ICellRendererParams} props - ag-grid props
   */
  async function editRegion(props: ICellRendererParams): Promise<void> {
    let foundRegion: Region = _.cloneDeep(props.data);

    if (props?.data?.updatedData) {
      foundRegion = { ...foundRegion, ...props.data.updatedData };
    }
    updateRegion(foundRegion)
      .unwrap()
      .then(() => {
        updateNodeData(props);
        message.success(`Successfully updated region`);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  }

  /**
   * Updates the node data
   *
   * @param {ICellRendererParams} props - ag-grid props
   */
  function updateNodeData(props: ICellRendererParams): void {
    if (props?.data?.updatedData) {
      Object.keys(props.data.updatedData).forEach((key: string) => {
        props.node.setDataValue(key, _.cloneDeep(props.data.updatedData[key]));
      });
      delete props.data.updatedData;
    }
    delete props.data.isEditActive;
    delete props.data.newRow;

    props.api.refreshCells({ rowNodes: [props.node], force: true });
  }

  //----------------------
  // AG-GRID FUNCTIONS
  //----------------------

  const frameworkComponents = {
    inputRenderer: (props: ICellRendererParams) => {
      return (
        <InputRenderer
          dataObject={props.data}
          onChangeCallback={(value: Region) =>
            onChange(value.regionName, props)
          }
          initialValues={{
            regionName: props.data.newRow ? null : props?.data?.regionName,
          }}
          showInput={props.data.isEditActive}
          formItemName="regionName"
          placeholder="Type region"
          inputClass="region-input"
          fallBackMessage={props.data.regionName}
          isTextArea={false}
        />
      );
    },
    actionsRenderer: (props: ICellRendererParams) => {
      return (
        <ActionsRenderer
          props={props}
          onDelete={onDelete}
          onSave={handleSave}
          onEdit={onEdit}
          onCancel={onCancel}
        />
      );
    },
  };

  /**
   * adds a new row to the table
   */
  function addNewRow(): void {
    //disable the add new region button so that only one new row can be added at a time
    setDisableAddNewRegionButton(true);

    //add a new row to the top of the table
    setPinnedTopRowData([
      {
        regionName: null,
        isEditActive: true,
        newRow: true,
      },
    ]);
  }

  return (
    <div className="region-settings-management">
      <div className="top-section" data-testid="top-section">
        <RegionAssignmentFilter
          gridApi={gridApi.current}
          regions={mapToTreeData(context.regions, "regionName")}
          disabled={isLoading}
        />
        <Button
          onClick={addNewRow}
          size="large"
          type="primary"
          icon={<PlusOutlined />}
          disabled={disableAddNewRegionButton || isLoading}
          data-testid="add-button"
        >
          Add Region
        </Button>
      </div>

      <div
        className="ag-theme-alpine header-white"
        data-testid="region-settings-table"
      >
        <AgGridReact
          rowData={_.cloneDeep(regions)}
          pinnedTopRowData={pinnedTopRowData}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          components={frameworkComponents}
          context={context}
          onGridSizeChanged={(props: GridSizeChangedEvent) =>
            props.api.sizeColumnsToFit()
          }
        />
      </div>
    </div>
  );
};

export default RegionSettingsManagement;
