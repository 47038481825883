import SelectionFilter from "@src/features/table_filtering/components/selection_filter/selection_filter";
import {
  RESOURCE_PLAN_FILTER_TYPES_V2,
  setCustomTableFilter,
} from "@src/services/resourcePlanSliceV2";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { TreeNode } from "@src/types/antd_types";

interface FilterResourcePlanProps {
  disabled: boolean;
  standardRoleNames: TreeNode[];
}

const FilterResourcePlan: React.FC<FilterResourcePlanProps> = ({
  disabled,
  standardRoleNames,
}) => {
  const dispatch = useAppDispatch();

  const {
    resourcePlanTableState: { customTableFilters },
  } = useAppSelector((state) => state.resourcePlanSliceV2);

  /**
   * updates the state of the custom table filter by the given type.
   *
   * @param {string[]} values - The values of the filter.
   * @param {RESOURCE_PLAN_FILTER_TYPES} type - The type of the filter.
   */
  function updateCustomTableFilterByType(
    values: string[],
    type: RESOURCE_PLAN_FILTER_TYPES_V2
  ) {
    dispatch(
      setCustomTableFilter({
        ...customTableFilters,
        [type]: values,
      })
    );
  }

  return (
    <SelectionFilter
      title="Role Filter"
      treeData={standardRoleNames}
      disabled={disabled}
      defaultKeys={customTableFilters.standardRoleName}
      onFilter={(values: string[]) => {
        updateCustomTableFilterByType(
          values,
          RESOURCE_PLAN_FILTER_TYPES_V2.STANDARD_ROLE_NAMES
        );
      }}
    />
  );
};

export default FilterResourcePlan;
