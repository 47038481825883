import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BaseTableFilters, BaseTableState } from "@src/types";
import { TabItem } from "@src/types/antd_types";
import { RoleRequest } from "@src/types/role_request_types";

export enum RESOURCE_PLAN_FILTER_TYPES_V2 {
  STANDARD_ROLE_NAMES = "standardRoleName",
  STATUS = "status",
}

export interface ResourcePlanFilters extends BaseTableFilters {
  [RESOURCE_PLAN_FILTER_TYPES_V2.STANDARD_ROLE_NAMES]: string[];
}

export type ResourcePlanTableFiltersV2 = BaseTableState<
  RoleRequest,
  ResourcePlanFilters
>;

export interface ResourcePlanStateV2 {
  projectId: string;
  resourcePlanTableState: ResourcePlanTableFiltersV2;
}

const initialState: ResourcePlanStateV2 = {
  projectId: "",
  resourcePlanTableState: {
    quickFilter: "",
    filterResult: [],
    dateFilter: null,
    customTableFilters: {
      standardRoleName: [],
    },
    columns: [],
    currentTabConfig: null,
    isFilterApplied: false,
  },
};

/**
 * This function is used to check if the filter is applied
 * @param state the state of the table
 * @returns true if the filter is applied, false otherwise
 */
function checkIfFilterApplied(state: ResourcePlanTableFiltersV2) {
  const { customTableFilters } = state;
  return customTableFilters.standardRoleName?.length !== 0;
}

const resourcePlanSliceV2 = createSlice({
  name: "resourcePlanSliceV2",
  initialState,
  reducers: {
    /**
     * Sets the project id state to the new state.
     * @param state the request overview filter state with all values.
     * @param projectId the project id string.
     */
    setProjectId: (
      state: ResourcePlanStateV2,
      projectId: PayloadAction<string>
    ) => {
      state.projectId = projectId.payload;
    },

    /**
     * Sets the quick filter state to the new state.
     *
     * @param state       the resource plan filter state with all values.
     * @param quickSearch the quick search string.
     */
    setQuickFilter: (
      state: ResourcePlanStateV2,
      quickSearch: PayloadAction<string>
    ) => {
      state.resourcePlanTableState.quickFilter = quickSearch.payload;
      state.resourcePlanTableState.isFilterApplied = checkIfFilterApplied(
        state.resourcePlanTableState
      );
    },

    /**
     * Sets the custom table filter state to the new state.
     *
     * @param state       the resource plan filter state with all values.
     * @param customTableFilters the custom table filters object with all filters.
     */
    setCustomTableFilter: (
      state: ResourcePlanStateV2,
      customTableFilters: PayloadAction<ResourcePlanFilters>
    ) => {
      state.resourcePlanTableState.customTableFilters =
        customTableFilters.payload;
      state.resourcePlanTableState.isFilterApplied = checkIfFilterApplied(
        state.resourcePlanTableState
      );
    },

    /**
     * Sets the columns to the new state.
     *
     * @param state the resource plan filter state with all values.
     * @param columns the columns to be set.
     */
    setColumns: (
      state: ResourcePlanStateV2,
      columns: PayloadAction<string[]>
    ) => {
      state.resourcePlanTableState.columns = columns.payload;
    },

    /**
     * Sets the current tab config to the new state.
     *
     * @param state
     * @param tabConfig
     */
    setCurrentTabConfig: (
      state: ResourcePlanStateV2,
      tabConfig: PayloadAction<TabItem>
    ) => {
      state.resourcePlanTableState.currentTabConfig = tabConfig.payload;
    },

    /**
     * Clears a specific property from the custom table filter.
     *
     * @param state The current filter state.
     * @param action Contains the key and value to be cleared.
     * @returns Updated state with the specified filter value removed.
     */
    clearCustomTableFilterProperty: (
      state: ResourcePlanStateV2,
      action: { payload: { key: string; value: string } }
    ): ResourcePlanStateV2 => {
      const { key, value } = action.payload;
      return {
        ...state,
        resourcePlanTableState: {
          ...state.resourcePlanTableState,
          customTableFilters: {
            ...state.resourcePlanTableState.customTableFilters,
            [key]: state.resourcePlanTableState.customTableFilters[key]?.filter(
              (item: string) => item !== value
            ),
          },
        },
      };
    },

    /**
     * Clears the filter state to the initial state.
     *
     */
    clearFilter: (state: ResourcePlanStateV2) => {
      return {
        ...state,
        resourcePlanTableState: {
          ...initialState.resourcePlanTableState,
          columns: state.resourcePlanTableState.columns, // preserve the columns
          quickFilter: state.resourcePlanTableState.quickFilter, // preserve the quick filter
        },
      };
    },
  },
});

export const {
  setProjectId,
  setQuickFilter,
  setCustomTableFilter,
  setColumns,
  setCurrentTabConfig,
  clearCustomTableFilterProperty,
  clearFilter,
} = resourcePlanSliceV2.actions;

export default resourcePlanSliceV2;
