import React, { useEffect, useMemo, useRef } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { REQUEST_STATUS } from "@src/constants";
import ActiveFilterBar from "@src/features/table_filtering/components/active_filters_bar/active_filters_bar";
import FiltersBar from "@src/features/table_filtering/components/filters_bar/filters_bar";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import { setColumns, setQuickFilter } from "@src/services/resourcePlanSliceV2";
import { useGetRoleRequestsByProjectIdQuery } from "@src/services/slices/projectsApi";
import { useAppDispatch, useAppSelector } from "@src/setupStore";
import { TableSettingsConfig } from "@src/types";
import { RoleRequest } from "@src/types/role_request_types";
import { GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { useResourcePlanStateHandler } from "../../hooks/use_resource_plan_state_handler";
import {
  getColumnDefs,
  getGridOptions,
} from "../../utils/resource_plan_table_grid_options";
import { FilterResourcePlan } from "../filter_resource_plan";
import { TabsResourcePlan } from "../tabs_resource_plan";

import "./resource_plan_table.less";

interface ResourcePlanTableProps {
  projectId: string;
  projectEndDate: Date;
}

const ResourcePlanTable: React.FC<ResourcePlanTableProps> = ({
  projectId,
  projectEndDate,
}) => {
  const gridApi = useRef<GridApi | null>(null);

  const columnDefs = useMemo(() => {
    return getColumnDefs(projectEndDate);
  }, [projectEndDate]);

  const { data: roleRequestData, isFetching } =
    useGetRoleRequestsByProjectIdQuery(projectId);

  const dispatch = useAppDispatch();

  const {
    resourcePlanTableState: { quickFilter, currentTabConfig },
  } = useAppSelector((state) => state.resourcePlanSliceV2);

  const { activeFilters, resetActiveFilters, setTableRendered } =
    useResourcePlanStateHandler({
      gridApi: gridApi.current,
      columnDefs,
    });

  // needs to be fixed in ticket 102487. Somehow this is not working
  useEffect(() => {
    const rowsCount: number = gridApi.current?.getDisplayedRowCount();
    if (rowsCount > 0) {
      gridApi.current?.hideOverlay();
    } else {
      gridApi.current?.showNoRowsOverlay();
    }
  }, [currentTabConfig]);

  function onGridReady(event: GridReadyEvent): void {
    gridApi.current = event.api;
  }

  function getTableSettings(): TableSettingsConfig {
    return {
      columns: columnDefs,
      onChange: (columns: string[]) => {
        dispatch(setColumns(columns));
      },
      columnsToHide: [],
      defaultHiddenColumns: ["Project Identifier", "Specific Role", "Scope"],
    };
  }

  function onQuickFilterChange(value: string): void {
    dispatch(setQuickFilter(value));
  }

  function getTabs(): React.ReactNode {
    return (
      <TabsResourcePlan
        disabled={false}
        amountOfDrafts={
          roleRequestData?.filter(
            (roleRequest: RoleRequest) =>
              roleRequest.status === REQUEST_STATUS.DRAFT
          ).length
        }
      />
    );
  }

  function getFilters(): React.ReactNode {
    return (
      <FilterResourcePlan
        disabled={isFetching}
        standardRoleNames={mapToTreeData(roleRequestData, "standardRoleName")}
      />
    );
  }

  return (
    <div className="resource-plan-table">
      <FiltersBar
        tabs={getTabs()}
        filters={getFilters()}
        tableSettings={getTableSettings()}
        quickFilterDefaultValue={quickFilter}
        onQuickFilterChange={onQuickFilterChange}
        isLoading={isFetching}
      />
      <ActiveFilterBar
        activeFilters={activeFilters}
        onRemove={(key: string, value: string) =>
          resetActiveFilters(key, value)
        }
        onRemoveAll={() => resetActiveFilters()}
      />
      <div className="ag-theme-alpine">
        <AgGridReact
          rowData={roleRequestData}
          gridOptions={getGridOptions()}
          onGridReady={onGridReady}
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "48px" },
          }}
          onColumnVisible={(props) => props.api.sizeColumnsToFit()}
          columnDefs={getColumnDefs(projectEndDate)}
          onFirstDataRendered={setTableRendered}
        />
      </div>
    </div>
  );
};

export default React.memo(ResourcePlanTable);
