import React from "react";

import "./comment_box.less";
import { Note } from "@src/types";
import { getInitials } from "@src/utils/helper";
import { Avatar } from "antd";

interface CommentBoxProps {
  note: Note;
}

const CommentBox: React.FC<CommentBoxProps> = ({ note }) => {
  function formatDate(input: string): string {
    const date: Date = new Date(input);
    const day: string = String(date.getDate()).padStart(2, "0");
    const month: string = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year: string = date.getFullYear().toString();

    return `${day}.${month}.${year}`;
  }

  return (
    <div className="comment-box" key={note.id}>
      <Avatar className="comment-box-avatar">
        {getInitials(note.employeeFullName)}
      </Avatar>
      <div className="comment-box-text-container">
        <div>
          <span className="comment-box-text-container-name">
            {note.employeeFullName}
          </span>
          <span className="comment-box-text-container-date">
            on {formatDate(note.createdDate)}
          </span>
        </div>
        <span className="comment-box-text-container-text">{note.text}</span>
      </div>
    </div>
  );
};

export default CommentBox;
