import React from "react";

import { Note } from "@src/types";
import "./staffing_request_comments_tab.less";
import { CommentBox } from "@src/components/comment_box";

interface StaffingRequestCommentsTabProps {
  notes: Note[];
}

const StaffingRequestCommentsTab: React.FC<StaffingRequestCommentsTabProps> = ({
  notes,
}) => {
  return (
    <div className="staffing-request-comments-tab">
      {notes?.map((note: Note) => (
        <CommentBox key={note.id} note={note} />
      ))}
      {notes?.length === 0 && (
        <div className="no-comments-message">
          <p>No comments yet</p>
        </div>
      )}
    </div>
  );
};

export default StaffingRequestCommentsTab;
