import React from "react";
import "./role_request_status_indicator.less";

interface RoleRequestStatusIndicatorProps {
  size?: "small" | "large";
  status: string;
}

const RoleRequestStatusIndicator: React.FC<RoleRequestStatusIndicatorProps> = ({
  status,
  size = "small",
}) => {
  const formatStatusClassName = (status: string) =>
    status?.toLowerCase()?.replace(" ", "-");

  return (
    <div
      data-testid="role_request_status_indicator"
      className={`role_request_status_indicator role_request_status_indicator-${size} role_request_status_indicator-${formatStatusClassName(
        status
      )}`}
    />
  );
};

export default RoleRequestStatusIndicator;
