import React, { useCallback, useRef, useState } from "react";

import { NoRowsOverlay } from "@src/components/overlays/no_rows_overlay";
import { StaffingRequestDetails } from "@src/features/staffing_request_details/components/staffing_request_details";
import TabsStaffingRequests from "@src/features/staffing_requests/components/tabs_staffing_requests/tabs_staffing_requests";
import { useStaffingRequestStateChangeHandler } from "@src/features/staffing_requests/hooks/use_staffing_request_state_change_handler";
import { ActiveFiltersBar } from "@src/features/table_filtering/components/active_filters_bar";
import FiltersBar, {
  DownloadConfig,
} from "@src/features/table_filtering/components/filters_bar/filters_bar";
import { handleColumnResize } from "@src/features/table_filtering/utils/base_state_handler_utils";
import { mapToTreeData } from "@src/features/table_filtering/utils/filter_utils";
import {
  setColumnConfigs,
  setColumns,
  setFilterResult,
  setQuickFilter,
} from "@src/services/requestOverviewSlice";
import { RootState, useAppSelector } from "@src/setupStore";
import { TableSettingsConfig, TeamLeadRequestOverviewItem } from "@src/types";
import { userUtils } from "@src/utils/login_utils";
import { ColDef, GridApi, GridReadyEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import { useDispatch } from "react-redux";

import { createGridOptions } from "../../utils/request_overview_grid_options";
import { FilterStaffingRequests } from "../filter_staffing_requests";
import "./request_overview.less";

interface RequestOverviewProps {
  requests: TeamLeadRequestOverviewItem[];
  isLoading: boolean;
}

const RequestOverview: React.FC<RequestOverviewProps> = ({
  requests,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const gridApi = useRef<GridApi | null>(null);
  const [columnDefs, setColumnDefs] = useState<ColDef[]>([]);

  const filterCallBack = useCallback(() => {
    if (gridApi.current) {
      const rows: TeamLeadRequestOverviewItem[] = [];
      gridApi.current.forEachNodeAfterFilter((node) => {
        if (node.data) {
          rows.push(_.cloneDeep(node.data));
        }
      });
      dispatch(setFilterResult([...rows]));
    }
  }, [dispatch]);

  const { activeFilters, resetActiveFilters, setTableRendered } =
    useStaffingRequestStateChangeHandler({
      gridApi,
      columnDefs,
      filterCallBack,
    });

  const {
    requestOverviewTableState: {
      filterResult,
      quickFilter,
      columns,
      columnConfigs,
    },
  } = useAppSelector((state: RootState) => state.requestOverviewSlice);

  const { NODE_ENV } = process.env;

  const employeeId =
    NODE_ENV === "development"
      ? `?employeeId=${userUtils.getEmployeeData().id}`
      : "";

  const downloadConfig: DownloadConfig = {
    path: `/reports/export-staffing-request-report${employeeId}`,
    downloadableData: requests,
    downloadableFilteredData: filterResult,
    method: "POST",
  };

  const onGridReady = (props: GridReadyEvent) => {
    gridApi.current = props.api;

    setColumnDefs(gridApi.current.getColumns().map((col) => col.getColDef()));
    gridApi.current.sizeColumnsToFit();
  };

  function getFilters() {
    return (
      <FilterStaffingRequests
        regions={mapToTreeData(requests, "region")}
        countries={mapToTreeData(requests, "country")}
        projectName={mapToTreeData(requests, "projectName")}
        requestedRole={mapToTreeData(requests, "requestedRoleName")}
        disabled={isLoading}
      />
    );
  }

  function getTabs() {
    return <TabsStaffingRequests disabled={isLoading} />;
  }

  function onQuickFilterChange(value: string) {
    return dispatch(setQuickFilter(value));
  }

  function getTableSettings(): TableSettingsConfig {
    return {
      columns: columnDefs,
      onChange: (columns: string[]) => {
        dispatch(setColumns(columns));
      },
      columnsToHide: [],
      selectedColumns: columns,
      lockedColumns: columnDefs
        .filter((col: ColDef) => col.lockVisible)
        .map((col: ColDef) => col.headerName),
      defaultHiddenColumns: [
        "Request ID",
        "Country",
        "Region",
        "Project Name",
        "Project Phase",
        "Country",
        "Region",
        "Tag",
        "Technology",
        "Required Action",
      ],
      compactViewSettings: {
        defaultCompacted: true,
        compactedColumns: ["Project Information", "Region/Country"],
        expandedColumns: ["Project Name", "Project Phase", "Country", "Region"],
      },
    };
  }

  return (
    <div className="request-overview">
      <FiltersBar
        drawerTitle="Filter Requests"
        isLoading={isLoading}
        tabs={getTabs()}
        filters={getFilters()}
        quickFilterDefaultValue={quickFilter}
        onQuickFilterChange={onQuickFilterChange}
        downloadConfig={downloadConfig}
        tableSettings={getTableSettings()}
      />
      <ActiveFiltersBar
        activeFilters={activeFilters}
        onRemove={(key: string, value: string) =>
          resetActiveFilters(key, value)
        }
        onRemoveAll={() => resetActiveFilters()}
      />
      <div className="ag-theme-alpine header-white" style={{ height: "100%" }}>
        <AgGridReact
          rowData={requests}
          gridOptions={createGridOptions()}
          onGridReady={onGridReady}
          noRowsOverlayComponent={NoRowsOverlay}
          noRowsOverlayComponentParams={{
            text: "No requests found",
            customStyle: { marginTop: "48px" },
          }}
          onColumnVisible={(props) => props.api.sizeColumnsToFit()}
          onFirstDataRendered={setTableRendered}
          onColumnResized={handleColumnResize(
            dispatch,
            setColumnConfigs,
            columnConfigs
          )}
        />
      </div>
      <StaffingRequestDetails />
    </div>
  );
};

export default React.memo(RequestOverview);
