import TreeSelectionWithTooltip from "@src/features/table_filtering/components/tree_selection_with_tooltip/tree_selection_with_tooltip";
import { TreeNode } from "@src/types/antd_types";
import { getDistinctList } from "@src/utils/helper";
import { ColDef, ColGroupDef } from "ag-grid-community";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";

/**
 * filters the treeData based on the given search text
 * @param {TreeNode[]} treeData treeData to be filtered
 * @param {string} searchText text that the filtering is based on
 * @returns filtered treeData
 */
export function getFilteredTreeData(
  treeData: TreeNode[],
  searchText: string
): TreeNode[] {
  if (!searchText) return treeData;
  const filteredOptions: TreeNode[] = [];
  treeData.forEach((treeItem: TreeNode) => {
    const treeItemTitle = treeItem.title.toString();
    if (treeItemTitle.toLowerCase().includes(searchText.toLowerCase())) {
      filteredOptions.push(treeItem);
    } else if (treeItem.children) {
      const filteredChildren: TreeNode[] = treeItem.children.filter(
        (child: TreeNode) =>
          child.title
            .toString()
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      if (filteredChildren.length > 0) {
        const newOption: TreeNode = _.cloneDeep(treeItem);
        newOption.children = filteredChildren;
        filteredOptions.push(newOption);
      }
    }
  });
  return filteredOptions;
}

/**
 * loops through an array with options (that can also contain children) and
 * search for the value in the given property
 * @param {T[]} data array with objects
 * @param {string} property property to search the array for
 * @returns an array with all values of the given property
 */
export function getPropertyValuesOfData<T>(data: T[], property: string): any[] {
  let newArray: any[] = [];
  data.forEach((item: T) => {
    newArray.push(item[property]);
    if (item["children"]) {
      newArray = newArray.concat(
        item["children"].map((child: T) => child[property])
      );
    }
  });
  return newArray;
}

/**
 * Converts a single data item (colDef) into a tree node for use in a tree component.
 *
 * @template T
 * @param {T[]} data - The array of data items to convert.
 * @param {string} [property] - Optional property name to extract from each data item. If not provided, the data items themselves are used.
 * @returns {TreeNode[]} - An array of tree nodes with distinct and sorted titles.
 */
export function mapToTreeData<T>(data: T[], property?: string): TreeNode[] {
  if (!data) return [];

  let options: string[] = property
    ? data.map((item: T) => item[property])
    : data;
  options = getDistinctList(options);

  return options
    .filter((option: string) => !!option)
    .map((option: string) => ({
      title: option,
      key: option,
    }));
}

/**
 * Converts an array of column definitions (ColDef or ColGroupDef) into an array of tree nodes for use in a tree component.
 * Each tree node will display a header name, and if a tooltip is provided, it will render a custom tooltip component.
 *
 * @param {Array<ColDef | ColGroupDef>} data - The array of column definitions to be converted into tree nodes.
 * @returns {TreeNode[]} - An array of tree nodes, each with a title and a key. Items without a header name are filtered out.
 */
export function mapColumnDefinitionsToTreeNodes(
  data: (ColDef | ColGroupDef)[]
): TreeNode[] {
  if (!data) return [];

  return data
    .filter((item) => item.headerName) // Filter out items without a headerName
    .map((item: ColDef | ColGroupDef) => ({
      title: item.headerTooltip ? (
        <TreeSelectionWithTooltip
          title={item.headerName}
          tooltip={item.headerTooltip}
        />
      ) : (
        item.headerName
      ),
      key: item["colKey"] || item.headerName,
    }));
}

/**
 * Converts an array of data into an array of tree nodes for use in a tree component.
 * Values from allocation fields will be nested in "years"
 *
 * @param {ColDef[] | ColGroupDef[]} colDefs - The array of data items to convert.
 * @param {string} [property] - property name to extract from each data item.
 * @param compactedColumns - array of column names to be shown in the compacted columns section
 * @returns {TreeNode[]} - An array of tree nodes with distinct and sorted titles.
 */
export function mapColumnsToTreeData(
  colDefs: (ColDef | ColGroupDef)[],
  property: string,
  compactedColumns: string[]
): TreeNode[] {
  if (!colDefs || !property) return [];

  const [allocationColDefs, allocationTreeData]: [ColDef[], TreeNode[]] =
    mapAllocationsToTreeData(colDefs);
  const [compactedColDefs, compactedTreeData]: [ColDef[], TreeNode[]] =
    mapCompactedColumnsToTreeData(colDefs, compactedColumns);
  const remainingTreeData: TreeNode[] = mapRemainingColumnsToTreeData(
    colDefs,
    allocationColDefs,
    compactedColDefs
  );

  const resultTreeData: TreeNode[] = [...remainingTreeData];

  // Insert compactedTreeData as the second item and allocationTreeData as the third item if possible
  if (resultTreeData.length >= 1) {
    resultTreeData.splice(1, 0, ...compactedTreeData, ...allocationTreeData);
  } else {
    resultTreeData.push(...compactedTreeData, ...allocationTreeData);
  }

  return resultTreeData;
}

function mapAllocationsToTreeData(
  colDefs: (ColDef | ColGroupDef)[]
): [ColDef[], TreeNode[]] {
  const allocationColDefs: ColDef[] = colDefs.filter(
    (colDef: ColDef | ColGroupDef) => colDef["groupId"] === "allocation"
  );

  // If there are compacted columns, return the tree node, otherwise return an empty array
  const treeData: TreeNode[] =
    allocationColDefs.length > 0
      ? [
          {
            title: "Years",
            key: "Years",
            children: mapColumnDefinitionsToTreeNodes(allocationColDefs),
          },
        ]
      : [];
  return [allocationColDefs, treeData];
}

function mapCompactedColumnsToTreeData(
  colDefs: (ColDef | ColGroupDef)[],
  compactedColumns: string[]
): [ColDef[], TreeNode[]] {
  const compactedColDefs: ColDef[] = colDefs.filter(
    (colDef: ColDef | ColGroupDef) =>
      compactedColumns?.includes(colDef.headerName)
  );

  // If there are compacted columns, return the tree node, otherwise return an empty array
  const treeData: TreeNode[] =
    compactedColDefs.length > 0
      ? [
          {
            title: "Compact View",
            key: "compacted",
            children: mapColumnDefinitionsToTreeNodes(compactedColDefs),
          },
        ]
      : [];
  return [compactedColDefs, treeData];
}

function mapRemainingColumnsToTreeData(
  colDefs: (ColDef | ColGroupDef)[],
  allocationColumns: ColDef[],
  compactedColumns: ColDef[]
): TreeNode[] {
  const allocationSet: Set<string> = new Set(
    allocationColumns.map((allocation) => allocation.headerName)
  );
  const compactedSet: Set<string> = new Set(
    compactedColumns.map((compacted) => compacted.headerName)
  );

  const rest: ColDef[] = colDefs.filter(
    (colDef: ColDef | ColGroupDef) =>
      !allocationSet.has(colDef.headerName) &&
      !compactedSet.has(colDef.headerName)
  );
  return mapColumnDefinitionsToTreeNodes(rest);
}

/**
 * This function is used to format the date filter for the active filter, so that it is displayed in the active filters bar.
 * The date filter is an array of two dates, so the function will format the first date and then the second date.
 * e.g [2023-02-01, 2023-02-10] will be formatted to 01/02/2023 - 10/02/2023
 *
 * @param dates the date filter array
 * @returns the formatted date filter
 */
export function formatDateActiveFilter(dates: string[]): string {
  return (
    dayjs(dates[0]).format("DD/MM/YYYY") +
    " - " +
    dayjs(dates[1]).format("DD/MM/YYYY")
  );
}

/**
 * Get all dates in the range.
 *
 * @param startDate {string}   start date of the workload (optional)
 * @param endDate {string}     end date of the workload (optional)
 * @param datesToCheck {string[]} dates to check if they are in the range
 * @returns {Object} An object with a boolean indicating if a filter was applied and an array of dates
 */
export function getAllDatesInRange(
  startDate?: string,
  endDate?: string,
  datesToCheck?: string[]
): { dates: string[]; filtered: boolean } {
  // If both startDate and endDate are undefined, no filter is applied
  if ((!startDate && !endDate) || !datesToCheck) {
    return { filtered: false, dates: [] };
  }

  const startDateMoment = startDate ? dayjs(startDate) : null;
  const endDateMoment = endDate ? dayjs(endDate) : null;
  const dates: string[] = [];

  for (const dateToCheck of datesToCheck) {
    if (isDateInRange(dateToCheck, startDateMoment, endDateMoment)) {
      dates.push(dayjs(dateToCheck).format("DD/MM/YYYY"));
    }
  }

  return { filtered: true, dates };
}

export const isDateInRange = (
  date: string,
  rangeStartDate: Dayjs,
  rangeEndDate: Dayjs
): boolean => {
  return dayjs(date).isBetween(rangeStartDate, rangeEndDate, "days", "[]");
};
