import { useCallback } from "react";

import { Action, Dispatch } from "@reduxjs/toolkit";
import {
  formatDateActiveFilter,
  getAllDatesInRange,
} from "@src/features/table_filtering/utils/filter_utils";
import { ActiveFilter, ColumnConfig } from "@src/types";
import {
  ColDef,
  ColGroupDef,
  Column,
  GridApi,
  RowNode,
} from "ag-grid-community";
import { ColumnResizedEvent } from "ag-grid-community/dist/types/core/events";
import _ from "lodash";

/**
 * Will filter the table based on the quick filter input.
 *
 * @param searchValue {string}   the searched value in the quick filter input to filter the table.
 * @param gridApi {React.MutableRefObject<GridApi>} the gridApi object
 * @param filterCallBack {() => void} the function to call when the filter is applied
 */
export function onChangeQuickFilter(
  searchValue: string,
  gridApi: GridApi,
  filterCallBack?: () => void
): void {
  gridApi.setGridOption("quickFilterText", searchValue);
  filterCallBack?.();
}

/**
 * Gathers active filters from the custom table filters and sets them as active filters.
 *
 * This function iterates through the custom table filters, and for each filter that has values,
 * it creates an array of `ActiveFilter` objects representing each filter type and value. It then
 * sets these active filters using the `setActiveFilters` function.
 */
export function gatherActiveFilters(
  customTableFilters: Record<string, string[]>,
  dateFilter?: string[]
): ActiveFilter[] {
  const activeFilters: ActiveFilter[] = [];

  if (dateFilter) {
    dateFilter.length > 0 &&
      activeFilters.push({
        filterType: "date",
        filterValue: formatDateActiveFilter(dateFilter),
      });
  }
  for (const key of Object.keys(customTableFilters)) {
    const values: string[] = customTableFilters[key];
    if (values.length !== 0) {
      for (const value of values) {
        activeFilters.push({
          filterType: key,
          filterValue: value,
        });
      }
    }
  }

  return activeFilters;
}

/**
 * Change column filter values to be displayed
 *
 * @param columnNames {string[]}  column values to be displayed
 * @param gridApi {GridApi} the gridApi object
 * @param columnDefs {ColDef[]} the column definitions
 */
export function onColumnFilterChange(
  columnNames: string[],
  gridApi: GridApi,
  columnDefs: (ColDef | ColGroupDef)[]
): void {
  if (columnNames.length === 0) return;

  columnDefs.forEach((colDef) => {
    // show or hide column
    if (colDef["field"]) {
      gridApi.setColumnsVisible(
        [colDef["field"]],
        columnNames.includes(colDef.headerName)
      );
    }
    // if it's allocation, all its children should be shown or hidden
    if (colDef["groupId"] === "allocation") {
      const childrenColIds: string[] = colDef["children"].map(
        (childColDef: ColDef) => childColDef["colId"]
      );
      gridApi.setColumnsVisible(
        childrenColIds,
        columnNames.includes(colDef.headerName)
      );
    }
  });
}

/**
 * Change filter values for the dropdown filter and action required filter
 *
 * @param selectedElements {string[]}     selected elements to be filtered (eg. countries, regions, technologies)
 * @param fieldTypeName {string[]}        fieldTypeName type to be filtered
 * @param gridApi
 * @param filterCallBack
 */
export function onFilterChange(
  selectedElements: string[],
  fieldTypeName: string,
  gridApi: GridApi,
  filterCallBack?: () => void
): void {
  gridApi.setColumnFilterModel(fieldTypeName, {
    values: selectedElements?.length !== 0 ? selectedElements : null,
  });
  gridApi.onFilterChanged();
  filterCallBack?.();
}

/**
 * Update the table based on the selected column configs.
 * @param columnConfigs {ColumnConfig[]}  column configs to be applied
 * @param gridApi {GridApi} the gridApi object
 */
export function onColumnConfigChange(
  columnConfigs: ColumnConfig[],
  gridApi: GridApi
) {
  const columnsByKey: Map<string, Column> = _.keyBy(
    gridApi.getColumns(),
    (col: Column) => col.getColDef().headerName
  );

  applyColumnWidthChange(columnConfigs, columnsByKey, gridApi);
}

/**
 * Apply the column width change to the grid.
 * @param columnConfigs {ColumnConfig[]}  column configs to be applied
 * @param columnsByKey {Map<string, Column>}  column objects  to be applied
 * @param gridApi {GridApi} the gridApi object
 */
function applyColumnWidthChange(
  columnConfigs: ColumnConfig[],
  columnsByKey: Map<string, Column>,
  gridApi: GridApi
) {
  if (!columnConfigs) return;
  const changedColumnWidths = columnConfigs?.filter(
    (columnConfig: ColumnConfig) => {
      const column = columnsByKey[columnConfig.key];
      return column && column.getActualWidth() !== columnConfig.width;
    }
  );

  gridApi.setColumnWidths(
    changedColumnWidths.map((column: ColumnConfig) => ({
      key: columnsByKey[column.key],
      newWidth: column.width,
    }))
  );
}

/**
 * Update the table based on the selected date range of the workload start date.
 *
 * @param dateRange {string[]}  start and end date of the range for workload start date.
 * @param field {string} the field to filter on
 * @param gridApi {GridApi} the gridApi object
 * @param filterCallBack {() => void} the function to call when the filter is applied
 */
export function onRangePickerChange(
  dateRange: string[] | null,
  field: string,
  gridApi: GridApi,
  filterCallBack?: () => void
): void {
  if (!dateRange || dateRange.length !== 2) {
    onFilterChange(null, field, gridApi, filterCallBack);
    return;
  }

  const [startDate, endDate] = dateRange;
  const dates: string[] = [];
  gridApi.forEachNode((rowNode: RowNode) => {
    dates.push(rowNode.data.roleRequestStart);
  });
  const result = getAllDatesInRange(startDate, endDate, dates);

  applyDateRangeFilter(result, field, gridApi, filterCallBack);
}

/**
 * Apply filter based on date range result.
 *
 * @param result The result from getAllDatesInRange
 * @param filterType The type of filter being applied
 * @param gridApi {GridApi} the gridApi object
 * @param filterCallBack {() => void} the function to call when the filter is applied
 */
function applyDateRangeFilter(
  result: { filtered: boolean; dates: string[] },
  filterType: string,
  gridApi: GridApi,
  filterCallBack?: () => void
): void {
  if (result.filtered && result.dates.length > 0) {
    onFilterChange(result.dates, filterType, gridApi, filterCallBack);
  }
}

/**
 * Resets all active filters and sets the visibility of columns based on the current tab configuration.
 *
 * @param {GridApi} gridApi - The API for interacting with the grid's data.
 * @returns {void}
 */
export function resetAllActiveFilters(gridApi: GridApi): void {
  gridApi.setFilterModel(null);
}

/**
 * Handles column resize events in the grid and updates the column configurations.
 * The function is debounced to prevent excessive updates during continuous resizing.
 *
 * @param {Dispatch} dispatch - The dispatch function for updating the state
 * @param {function} setColumnConfigs - The function to set the updated column configurations.
 * @param {ColumnConfig[]} currentColumnConfigs - The current list of column configurations.
 */
export function handleColumnResize(
  dispatch: Dispatch,
  setColumnConfigs: (columnConfigs: ColumnConfig[]) => Action,
  currentColumnConfigs: ColumnConfig[]
) {
  // Return the debounced handler for use in the component
  return useCallback(
    _.debounce((event: ColumnResizedEvent<any>) => {
      if (!event.column) return;

      const changedColumn: Column = event.column;
      const changedColumnConfig: ColumnConfig = {
        key: changedColumn.getColDef().headerName,
        width: changedColumn.getActualWidth(),
      };

      // Dispatch the updated column configurations.
      dispatch(
        setColumnConfigs(
          _.uniqBy([changedColumnConfig, ...currentColumnConfigs], "key")
        )
      );
    }, 1000),
    [dispatch, setColumnConfigs, currentColumnConfigs]
  );
}
