import React from "react";

import { Headline } from "@src/components/headline";
import { RegionAssignmentManagement } from "@src/features/region_assignment/components/region_assignment_management";
import { RegionSettingsManagement } from "@src/features/region_assignment/components/region_settings_management";
import { useGetRegionsQuery } from "@src/services/slices/regionsSlice";
import { TabItem } from "@src/types/antd_types";
import { Tabs } from "antd";
import "./region_assignment_page.less";

const RegionAssignmentPage: React.FC = () => {
  const { data: regionsData, isLoading } = useGetRegionsQuery();

  const tabs: TabItem[] = [
    {
      key: "1",
      label: `Region Assignment`,
      children: <RegionAssignmentManagement regionsData={regionsData} />,
    },
    {
      key: "2",
      label: `Regions`,
      children: (
        <RegionSettingsManagement
          regionsData={regionsData}
          isLoading={isLoading}
        />
      ),
    },
  ];

  return (
    <div className="region-assignment-page">
      <Headline title="Region Assignment" />
      <span data-testid="label">Edit regions and assign them</span>
      <Tabs type="card" size="large" tabPosition="top">
        {tabs.map((tab) => (
          <Tabs.TabPane tab={tab.label} key={tab.key}>
            {tab.children}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default RegionAssignmentPage;
