import React, { useEffect, useState } from "react";

import "./deputy_modal.less";
import { DeleteFilled } from "@ant-design/icons";
import {
  useAddDeputyMutation,
  useLazyGetDeputiesQuery,
} from "@src/services/slices/deputiesApi";
import { useLazyGetTeamLeadsStandardRolesQuery } from "@src/services/slices/teamLeadsApi";
import { sortAlphabetically } from "@src/utils/helper";
import { Button, Modal, Select, Form, Row, Col, Spin, message } from "antd";
import _ from "lodash";

function DeputyModal({
  setModalVisible,
  modalVisible,
  teamMembers,
  loggedInUser,
}) {
  const [deputyForm] = Form.useForm();
  const [standardRoles, setStandardRoles] = useState([]);
  const [areDeputiesAvailable, setAreDeputiesAvailable] = useState(false);
  const [areDeputiesLoading, setAreDeputiesLoading] = useState(true);
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(true);
  const [currentAndOldFormValues, setCurrentAndOldFormValues] = useState(null);
  const [deputyToDisable, setDeputyToDisable] = useState([]);

  const [getTeamLeadStandardRoles] = useLazyGetTeamLeadsStandardRolesQuery();
  const [addDeputy] = useAddDeputyMutation();
  const [getDeputies] = useLazyGetDeputiesQuery();

  const getDeputiesWithStandardRolesValues = () => {
    return deputyForm.getFieldValue("deputiesWithStandardRoles");
  };

  useEffect(() => {
    sortAlphabetically(teamMembers, "fullName");
  }, [teamMembers]);

  useEffect(() => {
    if (modalVisible) {
      getTeamLeadStandardRoles(loggedInUser.employeeId)
        .unwrap()
        .then((teamLeadStandardRoles) => {
          setStandardRoles(teamLeadStandardRoles);
        })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {});

      setCurrentAndOldFormValues({ oldDeputyValues: [], newDeputyValues: [] });

      fetchDeputies();
    }
  }, [modalVisible]);

  const fetchDeputies = async () => {
    getDeputies()
      .unwrap()
      .then((deputies) => {
        const alreadyAssignedDeputies = [];
        const deputyToDisableValues = [];

        for (const [index, deputyResult] of deputies.entries()) {
          deputyToDisableValues.push({
            index: index,
            employeeId: deputyResult.deputy,
          });
          setDeputyToDisable(deputyToDisableValues);

          setObjectWithOldAndNewValues(
            _.clone(deputyResult),
            getDeputiesWithStandardRolesValues(),
            alreadyAssignedDeputies
          );
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});

    setAreDeputiesLoading(false);
  };

  const saveDeputies = async () => {
    const deputyFormValues = deputyForm.getFieldsValue();

    const assignedDeputiesWithRoles = _.clone(
      deputyFormValues.deputiesWithStandardRoles
    );
    currentAndOldFormValues.newDeputyValues = assignedDeputiesWithRoles || [];
    setCurrentAndOldFormValues(currentAndOldFormValues);

    addDeputy(currentAndOldFormValues)
      .unwrap()
      .then(() => {
        message.success("Successfully saved deputies", 2);
        setModalVisible(false);
        setIsSaveBtnDisabled(true);
      })
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch(() => {});
  };

  const setObjectWithOldAndNewValues = async (
    data,
    formValues,
    alreadyAssignedDeputies
  ) => {
    const isDeputyNotCached = formValues
      ? formValues.findIndex(
          (deputyWithStandardRole) =>
            deputyWithStandardRole.deputy === data.deputy
        ) === -1
      : true;

    if (
      alreadyAssignedDeputies.findIndex(
        (value) => value.deputy === data.deputy
      ) === -1
    ) {
      alreadyAssignedDeputies.push({
        standardRoles: data.standardRolesObjects.map(
          (standardRole) => standardRole.standardRoleId
        ),
        deputy: data.deputy,
      });
    }

    if (isDeputyNotCached) {
      addNewDeputy(data.deputy, data.standardRolesObjects, "init");
    }

    setCurrentAndOldFormValues({
      oldDeputyValues: alreadyAssignedDeputies,
      newDeputyValues: [],
    });
  };

  const addNewDeputy = (selectedEmployeeId, standardRolesObjects, type) => {
    const field = getDeputiesWithStandardRolesValues();

    const roles =
      standardRolesObjects !== null
        ? standardRolesObjects.map((role) => role.standardRoleId)
        : standardRoles.map((standardRole) => standardRole.standardRoleId);

    if (!field || field.length === 0) {
      deputyForm.setFieldsValue({
        deputiesWithStandardRoles: [
          {
            standardRoles: roles,
            deputy: selectedEmployeeId,
          },
        ],
      });
    } else {
      field.push({ standardRoles: roles, deputy: selectedEmployeeId });
      deputyForm.setFieldsValue({ deputiesWithStandardRoles: field });
    }

    setIsAddBtnDisabled(true);
    setAreDeputiesAvailable(true);

    if (type !== "init") {
      deputyToDisable.push({
        index: deputyToDisable.length,
        employeeId: selectedEmployeeId,
      });
    }

    deputyForm.resetFields(["teamMember"]);
  };

  const deleteDeputy = (deputyIndexToDelete) => {
    //remove deputy from current list
    const deputies = getDeputiesWithStandardRolesValues();
    deputies.splice(deputyIndexToDelete, 1);
    deputyForm.setFieldsValue({ deputiesWithStandardRoles: deputies });

    const deputiesToDisable = [];

    for (let [i = 1, deputy] of deputies.entries()) {
      deputiesToDisable.push({ index: i, employeeId: deputy.deputy });
    }

    setDeputyToDisable(deputiesToDisable);

    if (deputies.length === 0) {
      setAreDeputiesAvailable(false);
    }

    deputyForm.validateFields();
    setIsSaveBtnDisabled(false);
    setSaveBtnDisableStatus(deputies);
  };

  const setNewFormValues = (index) => {
    const formValues = getDeputiesWithStandardRolesValues();

    currentAndOldFormValues.newDeputyValues = formValues;
    setCurrentAndOldFormValues(currentAndOldFormValues);

    //it was necessary to assign the new value via deputyForm.setFieldsValue because only changing the option to another
    //was not re-rendering the option field and the selection options were not correctly disabled
    const currentUpdatedDeputy = formValues[index];
    deputyForm.setFieldsValue(currentUpdatedDeputy);
    setSaveBtnDisableStatus(formValues);
  };

  const setSaveBtnDisableStatus = (deputyFormValues) => {
    const areValuesSame = _.isEqual(
      currentAndOldFormValues.oldDeputyValues,
      deputyFormValues
    );
    setIsSaveBtnDisabled(areValuesSame);
  };

  const setNewSelectedDeputy = (employeeId, obj) => {
    deputyToDisable[obj.index].employeeId = employeeId;
    setDeputyToDisable(deputyToDisable);
    setNewFormValues(obj.index);
    deputyForm.validateFields();
  };

  const disableSelection = (employeeId, index) => {
    const deputyToDisableIndex = deputyToDisable.find(
      (teamMember) => teamMember.employeeId === employeeId
    );

    if (deputyToDisableIndex) {
      return (
        deputyToDisableIndex !== -1 && index !== deputyToDisableIndex.index
      );
    } else {
      return false;
    }
  };

  const checkIfTheSelectedTeamMemberIsDeputy = (val, employeeId) => {
    let isDeputyInListAlready = false;

    const deputiesWithStandardRoles = getDeputiesWithStandardRolesValues();

    if (deputiesWithStandardRoles) {
      isDeputyInListAlready = !!deputiesWithStandardRoles.find(
        (d) => d.deputy === employeeId
      );
    }

    if (!employeeId) {
      setIsAddBtnDisabled(true);
    } else if (isDeputyInListAlready) {
      setIsAddBtnDisabled(true);
      return Promise.reject(new Error("This Employee is already a deputy"));
    } else {
      setIsAddBtnDisabled(false);
    }
  };

  return (
    <>
      <Modal
        title="Team settings"
        width="1000px"
        open={modalVisible}
        maskClosable={false}
        centered
        closable={false}
        bodyStyle={{ width: "100%" }}
        onCancel={() => setModalVisible(false)}
        okText="Save"
        onOk={() => saveDeputies()}
        okButtonProps={{ disabled: isSaveBtnDisabled }}
        className="deputy-modal"
      >
        <div>
          <Form
            autoComplete="off"
            form={deputyForm}
            size="large"
            layout="vertical"
          >
            <Row align="bottom">
              <Col flex="auto">
                <Form.Item
                  name="teamMember"
                  rules={[{ validator: checkIfTheSelectedTeamMemberIsDeputy }]}
                  label="Deputy settings"
                >
                  <Select
                    showSearch
                    placeholder="Select a Deputy"
                    optionFilterProp="children"
                    data-testid="select-deputy"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {teamMembers.map((member) => {
                      return (
                        <Select.Option
                          value={member.employeeId}
                          key={member.employeeId}
                        >
                          {member.fullName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="col">
                <Button
                  onClick={() => {
                    addNewDeputy(deputyForm.getFieldValue("teamMember"), null);
                    setIsSaveBtnDisabled(false);
                  }}
                  type="primary"
                  className="add-button"
                  data-testid="add-button"
                  disabled={isAddBtnDisabled}
                >
                  Add
                </Button>
              </Col>
            </Row>

            <div className="assigned-deputies-section">
              {areDeputiesLoading ? (
                <>
                  <div className="loading-info">
                    <Spin tip="Loading..." />
                  </div>
                </>
              ) : (
                <>
                  {!areDeputiesAvailable ? (
                    <div className="loadingInfo">
                      <span>No Deputies available</span>
                    </div>
                  ) : (
                    <table>
                      <Form.List name="deputiesWithStandardRoles">
                        {(fields) => (
                          <>
                            <thead>
                              <th>Standard role</th>
                              <th>Deputy</th>
                              <th>Delete</th>
                            </thead>

                            <tbody>
                              {fields.map(({ key, name }) => (
                                <>
                                  <tr>
                                    <td>
                                      <Form.Item name={[name, "standardRoles"]}>
                                        <Select
                                          showSearch
                                          mode="multiple"
                                          placeholder="Select a standard role"
                                          value={standardRoles}
                                          defaultValue={standardRoles}
                                          optionFilterProp="children"
                                          style={{ width: "100%" }}
                                          onChange={setNewFormValues}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {standardRoles.map((standardRole) => {
                                            return (
                                              <Select.Option
                                                value={
                                                  standardRole.standardRoleId
                                                }
                                                key={
                                                  standardRole.standardRoleId
                                                }
                                              >
                                                {standardRole.standardRoleName}
                                              </Select.Option>
                                            );
                                          })}
                                        </Select>
                                      </Form.Item>
                                    </td>

                                    <td>
                                      <Form.Item
                                        name={[name, "deputy"]}
                                        shouldUpdate
                                      >
                                        <Select
                                          showSearch
                                          placeholder="Select a Deputy"
                                          optionFilterProp="children"
                                          onChange={setNewSelectedDeputy}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                        >
                                          {teamMembers.map((member) => {
                                            return (
                                              <Select.Option
                                                value={member.employeeId}
                                                key={member.employeeId}
                                                index={key}
                                                disabled={disableSelection(
                                                  member.employeeId,
                                                  key
                                                )}
                                              >
                                                {member.fullName}
                                              </Select.Option>
                                            );
                                          })}
                                        </Select>
                                      </Form.Item>
                                    </td>
                                    <td>
                                      <DeleteFilled
                                        onClick={() => deleteDeputy(name)}
                                      />
                                    </td>
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          </>
                        )}
                      </Form.List>
                    </table>
                  )}
                </>
              )}
            </div>
          </Form>
        </div>
      </Modal>
    </>
  );
}

export default DeputyModal;
