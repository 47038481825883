import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ColumnConfig,
  RequestOverviewState,
  RequestOverviewTableFilters,
} from "@src/types";
import { TabItem } from "@src/types/antd_types";

const initialState: RequestOverviewState = {
  selectedRequestId: undefined,
  detailsModalVisible: false,
  requestOverviewTableState: {
    quickFilter: "",
    filterResult: [],
    dateFilter: null,
    customTableFilters: {
      region: [],
      country: [],
      technologies: [],
      status: [],
      projectPhase: [],
      projectName: [],
      requestedRoleName: [],
    },
    columns: [],
    currentTabConfig: null,
    isFilterApplied: false,
    columnConfigs: [],
  },
};

const checkIfFilterApplied = (state: RequestOverviewState) => {
  const { quickFilter, dateFilter, customTableFilters } =
    state.requestOverviewTableState;
  const customFilters = Object.values(customTableFilters).flat();
  return (
    quickFilter !== "" || dateFilter?.length > 0 || customFilters.length > 0
  );
};

const requestOverviewSlice = createSlice({
  name: "requestOverview",
  initialState: initialState,
  reducers: {
    /**
     * Sets the quick filter state to the new state.
     *
     * @param state       the request overview filter state with all values.
     * @param quickSearch the quick search string.
     */
    setQuickFilter: (
      state: RequestOverviewState,
      quickSearch: PayloadAction<string>
    ) => {
      state.requestOverviewTableState.quickFilter = quickSearch.payload;
      state.requestOverviewTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },
    /**
     * Sets the date filter state to the new state.
     *
     * @param state          the request overview filter state with all values.
     * @param filteredDates  the filtered dates.
     */
    setDateFilter: (
      state: RequestOverviewState,
      filteredDates: PayloadAction<string[]>
    ) => {
      state.requestOverviewTableState.dateFilter = filteredDates.payload;
      state.requestOverviewTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },
    /**
     * Sets the custom filter state to the new state.
     *
     **
     * @param state                   the request overview filter state with all values.
     * @param action
     */
    setCustomTableFilter: (
      state: RequestOverviewState,
      action: PayloadAction<RequestOverviewTableFilters>
    ) => {
      state.requestOverviewTableState.customTableFilters = action.payload;
      state.requestOverviewTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },

    /**
     *  Sets the request overview filter result to the new state.
     *
     * @param state
     * @param action
     */
    setFilterResult: (state: RequestOverviewState, action) => {
      state.requestOverviewTableState.filterResult = action.payload;
      state.requestOverviewTableState.isFilterApplied =
        checkIfFilterApplied(state);
    },

    /**
     * Sets the current tab config to the new state.
     *
     * @param state
     * @param tabConfig
     */
    setCurrentTabConfig: (
      state: RequestOverviewState,
      tabConfig: PayloadAction<TabItem>
    ) => {
      state.requestOverviewTableState.currentTabConfig = tabConfig.payload;
    },

    /**
     * Sets the columns to the new state.
     *
     * @param state
     * @param columns
     */
    setColumns: (
      state: RequestOverviewState,
      columns: PayloadAction<string[]>
    ) => {
      state.requestOverviewTableState.columns = columns.payload;
    },

    /**
     * Sets the column configs to the new state.
     *
     * @param state
     * @param columnConfigs
     */
    setColumnConfigs: (
      state: RequestOverviewState,
      columnConfigs: PayloadAction<ColumnConfig[]>
    ) => {
      state.requestOverviewTableState.columnConfigs = columnConfigs.payload;
    },

    setDetailsModalVisible: (
      state: RequestOverviewState,
      detailsModalVisible: PayloadAction<boolean>
    ) => {
      state.detailsModalVisible = detailsModalVisible.payload;
    },

    setSelectedRequestId: (
      state: RequestOverviewState,
      selectedRequestId: PayloadAction<number>
    ) => {
      state.selectedRequestId = selectedRequestId.payload;
    },

    /**
     * Clears a specific property from the custom table filter.
     *
     * @param state The current filter state.
     * @param action Contains the key and value to be cleared.
     * @returns Updated state with the specified filter value removed.
     */
    clearCustomTableFilterProperty: (
      state: RequestOverviewState,
      action: { payload: { key: string; value: string } }
    ): RequestOverviewState => {
      const { key, value } = action.payload;
      return {
        ...state,
        requestOverviewTableState: {
          ...state.requestOverviewTableState,
          customTableFilters: {
            ...state.requestOverviewTableState.customTableFilters,
            [key]: state.requestOverviewTableState.customTableFilters[
              key
            ]?.filter((item: string) => item !== value),
          },
        },
      };
    },

    /**
     * Clears the filter state to the initial state.
     *
     */
    clearFilter: (state: RequestOverviewState): RequestOverviewState => {
      return {
        ...initialState,
        requestOverviewTableState: {
          ...initialState.requestOverviewTableState,
          columns: state.requestOverviewTableState.columns, // preserve the columns
          quickFilter: state.requestOverviewTableState.quickFilter, // preserve the quick filter
          currentTabConfig: state.requestOverviewTableState.currentTabConfig, // preserve the current tab config
        },
      };
    },

    /**
     * Clears all the filters and sets the table to the initial state.
     */
    clearAll: (state: RequestOverviewState): RequestOverviewState => {
      return {
        ...initialState,
        requestOverviewTableState: {
          ...initialState.requestOverviewTableState,
          columns: state.requestOverviewTableState.columns, // preserve the columns
        },
      };
    },
  },
});

export const {
  clearAll,
  clearFilter,
  setColumnConfigs,
  setDateFilter,
  setQuickFilter,
  setCurrentTabConfig,
  setCustomTableFilter,
  setFilterResult,
  setColumns,
  setSelectedRequestId,
  setDetailsModalVisible,
  clearCustomTableFilterProperty,
} = requestOverviewSlice.actions;

export default requestOverviewSlice;
