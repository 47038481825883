import {
  columnTypes,
  paginationGridOptions,
  preventPinnedColumnJumpingGridOption,
} from "@src/utils/aggrid_utils";
import { ColDef, GridOptions } from "ag-grid-community";

const columnDefs: ColDef[] = [
  {
    headerName: "Country",
    field: "countryName",
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Region",
    field: "regionName",
    cellRenderer: "selectionRenderer",
    filter: "agSetColumnFilter",
  },
  {
    headerName: "Actions",
    field: "actions",
    suppressSizeToFit: true,
    width: 200,
    pinned: "right",
    cellRenderer: "actionsRenderer",
    sortable: false,
    resizable: false,
  },
];

export function getGridOptions(): GridOptions {
  return {
    ...paginationGridOptions,
    ...preventPinnedColumnJumpingGridOption,
    domLayout: "autoHeight",
    suppressContextMenu: true,
    suppressClickEdit: true,
    animateRows: true,
    rowHeight: 55,
    columnDefs: columnDefs,
    defaultColDef: {
      ...columnTypes.default,
      ...columnTypes.cellWrapText,
    },
    context: {
      region: [],
    },
  };
}
