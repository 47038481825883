import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";

const cookies = new Cookies();

interface AuthorizedContentProps {
  roles: string[];
  children: React.ReactNode;
}

const AuthorizedContent: React.FC<AuthorizedContentProps> = ({
  children,
  roles,
}) => {
  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    const user = cookies.get("loggedInuser");
    setIsAuthorized(user ? userHasAccess(user.applicationRoles, roles) : false);
  }, [roles]);

  function userHasAccess(userRoles: string[], authRoles: string[]): boolean {
    return userRoles?.some((userRole: string) => {
      return authRoles.some(
        (authRole: string) => authRole.toUpperCase() === userRole.toUpperCase()
      );
    });
  }

  return isAuthorized ? <>{children}</> : <></>;
};

export default AuthorizedContent;
