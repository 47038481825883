import React from "react";

import { RoleRequestStatusIndicator } from "@src/components/role_request_status_indicator";
import { Col, Row } from "antd";
import "./role_request_modal_header.less";

interface RoleRequestModalHeaderProps {
  requestedRoleId: number;
  requestedRoleStatus: string;
  requestedRoleName: string;
}

const RoleRequestModalHeader: React.FC<
  Partial<RoleRequestModalHeaderProps>
> = ({ requestedRoleStatus, requestedRoleId, requestedRoleName }) => {
  return (
    <>
      <Row className="role-request-header">
        <Col className="role-request-header-indicator">
          <RoleRequestStatusIndicator
            size="large"
            status={requestedRoleStatus}
          />
        </Col>
        <Col>
          <div className="role-request-header-title">
            {requestedRoleStatus} - {requestedRoleId} - {requestedRoleName}
          </div>
        </Col>
      </Row>
      <Row className="role-request-header-notification">
        {/*TODO: Add notifications*/}
        <div>mock</div>
      </Row>
    </>
  );
};

export default RoleRequestModalHeader;
